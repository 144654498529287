<template>
  <footer>
    <div class="footer-mobile column-me" v-if="homeItem&&isMobile" v-cloak>
      <div class="column-me margin-top50 flex1">
        <div class="column-me margin-left20">
          <div class="title1">加盟热线：</div>
          <div class="title2">{{ homeItem.conf.join_phone }}</div>
        </div>
        <div class="column-me center-div margin-top50">
          <div class="row-me row-center">
            <img src="../assets/img/pos.png" class="img-icon1">
            <div class="title3 flex1">{{ homeItem.conf.address }}</div>
          </div>
          <div class="row-me row-center margin-top20">
            <img src="../assets/img/tel.png" class="img-icon2">
            <div class="title3 flex1">{{ homeItem.conf.contacts }}</div>
          </div>
          <div class="row-me row-center margin-top20">
            <img src="../assets/img/msg.png" class="img-icon2">
            <div class="title3 flex1">{{ homeItem.conf.email }}</div>
          </div>
        </div>
        <div class="row-me center-all margin-top50">
          <div class="column-me row-center">
            <img :src="homeItem.conf.wx_qrcode" class="img-code">
            <div class="title4">扫码关注公众号</div>
          </div>
          <div class="column-me row-center margin-left45">
            <img :src="homeItem.conf.contacts_qrcode" class="img-code">
            <div class="title4">扫码联系客服</div>
          </div>
        </div>
      </div>
      <div class="bottom-div row-me center-all">
        <div class="title">Copyright©️青岛红尾狐俱乐部有限公司 版权所有</div>
      </div>
    </div>
    <div class="footer column-me" v-if="homeItem&&!isMobile" v-cloak>
      <div class="row-me margin-top50 flex1">
        <div class="column-me margin-left100">
          <div class="title1">加盟热线：</div>
          <div class="title2">{{ homeItem.conf.join_phone }}</div>
        </div>
        <div class="column-me flex1 center-div">
          <div class="row-me row-center">
            <img src="../assets/img/pos.png" class="img-icon">
            <div class="title3">{{ homeItem.conf.address }}</div>
          </div>
          <div class="row-me row-center margin-top25">
            <img src="../assets/img/tel.png" class="img-icon">
            <div class="title3">{{ homeItem.conf.contacts }}</div>
          </div>
          <div class="row-me row-center margin-top25">
            <img src="../assets/img/msg.png" class="img-icon">
            <div class="title3">{{ homeItem.conf.email }}</div>
          </div>
        </div>
        <div class="row-me margin-right100">
          <div class="column-me row-center">
            <img :src="homeItem.conf.wx_qrcode" class="img-code">
            <div class="title4">扫码关注公众号</div>
          </div>
          <div class="column-me row-center margin-left45">
            <img :src="homeItem.conf.contacts_qrcode" class="img-code">
            <div class="title4">扫码联系客服</div>
          </div>
        </div>
      </div>
      <div class="bottom-div row-me center-all">
        <div class="title">Copyright©青岛星之河动力科技有限公司&emsp;版权所有</div>
        <div class="row-me row-center margin-left50 pointer" @click="clickAn">
          <img src="../assets/img/jing.png" style="float:left;"/>
          <p class="margin-left5" style="color:#939393;">
            鲁公网安备&nbsp;&nbsp;37021002001003号
          </p>
        </div>
        <div class="margin-left50 pointer" style="color:#939393;" @click="clickICP">
          鲁ICP备19046685号-1
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      homeItem: null,
      isMobile: false,
    }
  },
  // html加载完成之前
  created() {
    this.getHomeData()
  },
  // html加载完成后执行。
  mounted() {
    this.isMobile = sessionStorage.getItem('mobile') === '1'
  },
  // 事件方法执行
  methods: {
    //获取数据
    getHomeData() {
      this.$postRequest('/api/index/index', {}).then((res) => {
        if (res) {
          this.homeItem = res.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    clickAn() {
      window.open('https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37021002001003')
    },
    clickICP() {
      window.open('https://beian.miit.gov.cn/')
    },
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {
    $route: {
      handler(to, from) {
        this.getHomeData()
      },
      deep: true
    },
  }
}
</script>

<style lang="scss" scoped>
.footer-mobile {
  background: #222222;

  .center-div {
    margin-left: 30px;
  }

  .title1 {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
  }

  .title2 {
    font-size: 30px;
    color: #FADA0F;
    font-family: Myriad Pro;
    font-weight: bold;
    margin-top: 20px;
  }

  .title3 {
    font-size: 25px;
    color: #FFFFFF;
    margin-left: 20px;
  }

  .title4 {
    width: 120px;
    text-align: justify;
    text-align-last: justify;
    font-size: 16px;
    color: #FFFFFF;
    margin-top: 8px;
  }

  .img-icon1 {
    width: 17px;
    height: 20px;
  }

  .img-icon2 {
    width: 20px;
    height: 17px;
  }

  .img-code {
    width: 130px;
    height: 130px;
    object-fit: fill;
  }

  .bottom-div {
    height: 60px;
    background-color: #333333;
    margin-top: 30px;

    .title {
      font-size: 16px;
      color: #FFFFFF;
    }
  }
}

.footer {
  height: 300px;
  background: #222222;

  .center-div {
    margin-left: 160px;
  }

  .title1 {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
  }

  .title2 {
    font-size: 40px;
    color: #FADA0F;
    font-family: Myriad Pro;
    font-weight: bold;
    margin-top: 30px;
  }

  .title3 {
    font-size: 20px;
    color: #FFFFFF;
    margin-left: 20px;
  }

  .title4 {
    width: 120px;
    text-align: justify;
    text-align-last: justify;
    font-size: 16px;
    color: #FFFFFF;
    margin-top: 8px;
  }

  .img-icon {
    width: 20px;
    height: 20px;
    object-fit: scale-down;
  }

  .img-code {
    width: 130px;
    height: 130px;
    object-fit: scale-down;
  }

  .bottom-div {
    height: 60px;
    background-color: #333333;

    .title {
      font-size: 16px;
      color: #FFFFFF;
    }
  }
}
</style>
