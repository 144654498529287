import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//引入ElementPlus
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
// 引入公用的js
import $ from './assets/js/js.js'
// 引入公用的样式文件
import './assets/css/base.css';
import './assets/css/main.css';
// 兼容IE   开始
import '@babel/polyfill'
import Es6Promise from 'es6-promise';
require('es6-promise').polyfill()
Es6Promise.polyfill();
// 兼容IE   结束
// px2rem 自适应
import './assets/js/lib-flexible/flexible.js'
//手机端控制台
// import vConsole from './http/vconsole'
import {
	dragscrollNext
} from 'vue-dragscroll'
// 网络请求
import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
	uploadFileRequest
} from './http/http.js'

// 路由拦截判断
router.beforeEach((to, from, next) => {
	// 如果路由不一样，滚动到最顶部
	if (to.name != from.name) {
		window.scrollTo(0, 0);
	}
	if (to.meta.title) {
		$.title(to.meta.title);
	}
	next();
})

const app = createApp(App)

app.use(router)
app.use(ElementPlus)
app.directive('dragscroll', dragscrollNext);

app.config.globalProperties.$ = $
app.config.globalProperties.$getRequest = getRequest
app.config.globalProperties.$postRequest = postRequest
app.config.globalProperties.$deleteRequest = deleteRequest
app.config.globalProperties.$putRequest = putRequest
app.config.globalProperties.$uploadFileRequest = uploadFileRequest
import Bus from './assets/js/bus.js' /// mitt 总线程引入
app.config.globalProperties.bus = Bus

app.mount('#app')
