<template>
  <div class="header-mobile row-me row-center" v-if="isMobile">
    <div class="row-me row-center mobile-title-div">
      <img src="../assets/img/logo.png" alt="" class="img-logo">
      <div class="mobile-title" @click="showMenu">{{ mobileTitle }}</div>
      <img src="../assets/img/meng_login.png" class="img-login" @click="clickMengLogin">
      <img src="../assets/img/menu.png" alt="" class="mobile-menu-img" @click="showMenu">
    </div>
    <div class="mask" v-if="menuShow" @click="menuShow=false"></div>
    <div class="column-me menu-div-mobile" v-show="menuShow">
      <div class="mobile-menu-item" v-for="(item,index) in tabArray" :key="index">
        <div @click.stop="clickTab(item,index)">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
  <!-- 电脑端 -->
  <div class="header row-me row-center" v-else>
    <div class="part1 row-me center-all">
      <img src="../assets/img/logo.png" class="img-logo">
    </div>
    <div class="flex1 row-me row-center flex-sb height100Per margin-left180 margin-right100">
      <div class="column-me row-center item-tab" :class="{'item-tab-active':tabIndex===index}"
           v-for="(item,index) in tabArray" :key="index" @click="clickTab(item,index)">
        <div class="tab-title" :class="{'tab-title-active':tabIndex===index}">{{ item.title }}</div>
        <div class="tab-subtitle line1" :class="{'tab-subtitle-active':tabIndex===index}">{{ item.subtitle }}
        </div>
        <div class="tab-line" :class="{'tab-line-active':tabIndex===index}"></div>
      </div>
    </div>
    <img src="../assets/img/meng_login.png" class="img-login anim-bigger-small" @click="clickMengLogin">
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      tabIndex: 0,
      tabArray: [],
      isMobile: false,
      menuShow: false,
      mobileTitle: '首页',
    }
  },
  // html加载完成之前
  created() {
    let temp = []
    temp.push({
      id: 0,
      title: '首页',
      subtitle: 'HOME',
      url: '/'
    })
    temp.push({
      id: 0,
      title: '品牌介绍',
      subtitle: 'ABOUT US',
      url: '/about_us'
    })
    temp.push({
      id: 0,
      title: '卡丁车',
      subtitle: 'GO-KART',
      url: '/sport_park'
    })
    // temp.push({
    // 	id: 0,
    // 	title: '产品中心',
    // 	subtitle: 'PRODUCTS',
    // 	url: '/product'
    // })
    // temp.push({
    // 	id: 0,
    // 	title: '课程中心',
    // 	subtitle: 'COURSE',
    // 	url: '/course'
    // })
    // temp.push({
    // 	id: 0,
    // 	title: '加盟中心',
    // 	subtitle: 'FRANCHISE',
    // 	url: '/franchise'
    // })
    temp.push({
      id: 0,
      title: '小狐出行',
      subtitle: 'XIAOHU TRAVEL',
      url: '/travel'
    })
    temp.push({
      id: 0,
      title: '服务支持',
      subtitle: 'SERVICE',
      url: '/service'
    })
    temp.push({
      id: 0,
      title: '衍生品',
      subtitle: 'DERIVATIVE',
      url: '/derivative'
    })
    temp.push({
      id: 0,
      title: '联系我们',
      subtitle: 'CONTACTS',
      url: '/contact'
    })
    this.tabArray = temp
    let index = sessionStorage.getItem('tabIndex')
    if (index !== null && index !== 'null') {
      this.tabIndex = Number(index)
    }
  },
  // html加载完成后执行。
  mounted() {
    this.isMobile = sessionStorage.getItem('mobile') === '1'
    let path = this.$route.path
    if (path.indexOf('home') !== -1) {
      this.tabIndex = 0
    } else if (path.indexOf('about_us') !== -1) {
      this.tabIndex = 1
    } else if (path.indexOf('sport_park') !== -1) {
      this.tabIndex = 2
    } else if (path.indexOf('travel') !== -1) {
      this.tabIndex = 3
    } else if (path.indexOf('service') !== -1) {
      this.tabIndex = 4
    } else if (path.indexOf('derivative') !== -1) {
      this.tabIndex = 5
    } else if (path.indexOf('contact') !== -1) {
      this.tabIndex = 6
    }
    this.mobileTitle = this.$route.meta.title
  },
  // 事件方法执行
  methods: {
    showMenu() {
      this.menuShow = !this.menuShow
    },
    clickTab(item, index) {
      this.tabIndex = index
      this.mobileTitle = item.title
      this.menuShow = false
      sessionStorage.setItem('tabIndex', this.tabIndex)
      this.$router.push({
        path: item.url,
        query: {},
      })
    },
    //加盟商登陆
    clickMengLogin() {
      window.open('http://karting.qdsingel.com/operator/passport/login')
    },
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {
    $route: {
      handler(to, from) {
        this.menuShow = false
        let path = to.name
        if (path.indexOf('home') !== -1) {
          this.tabIndex = 0
        } else if (path.indexOf('about_us') !== -1) {
          this.tabIndex = 1
        } else if (path.indexOf('sport_park') !== -1) {
          this.tabIndex = 2
        } else if (path.indexOf('travel') !== -1) {
          this.tabIndex = 3
        } else if (path.indexOf('service') !== -1) {
          this.tabIndex = 4
        } else if (path.indexOf('derivative') !== -1) {
          this.tabIndex = 5
        } else if (path.indexOf('contact') !== -1) {
          this.tabIndex = 6
        }
        this.mobileTitle = this.$route.meta.title
      },
      // 深度观察监听
      deep: true
    }
  },
}
</script>

<style lang="scss" scoped>
.header-mobile {
  height: 60px;
  background: #FFFFFF;
  border-bottom: 6px solid #000000;

  .mobile-title-div {
    width: 100%;
    height: 60px;
    background: rgba(255, 255, 255, 1);
    position: fixed;
    z-index: 9999;
    left: 0;
    right: 0;
    top: 0;

    .img-logo {
      width: 150px;
      height: 40px;
      margin-left: 10px;
      user-select: none;
      pointer-events: none;
    }

    .img-login {
      width: 60px;
      height: 25px;
      margin-right: 10px;
    }

    .mobile-logo-img {
      height: 30px;
      margin-left: 10px;
    }

    .mobile-menu-img {
      width: fit-content;
      height: 100%;
      object-fit: scale-down;
      padding-right: 20px;
    }

    .mobile-title {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      flex: 1;
    }
  }

  .mask {
    position: fixed;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9000;
  }

  .menu-div-mobile {
    width: 150px;
    height: auto;
    position: fixed;
    z-index: 9999;
    top: 60px;
    right: 0;
    box-shadow: 0 0 10px #eeeeee;

    .mobile-menu-item {
      width: 100%;
      text-align: center;
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      color: #333333;
      background: #FFFFFF;
      border-bottom: 1px solid #f6f6f6;
    }
  }
}

.header {
  height: 120px;
  background: #FFFFFF;
  border-bottom: 6px solid #000000;

  .part1 {
    margin-left: 100px;

    .img-logo {
      height: 70px;
      object-fit: scale-down;
      user-select: none;
      pointer-events: none;
    }
  }

  .item-tab {
    width: 130px;
    height: 100%;
    justify-content: flex-end;
    cursor: pointer;

    &-active {
      background: #E60012;
    }

    &:hover {
      font-weight: bold;
    }

    .tab-title {
      font-size: 16px;
      color: #000000;

      &-active {
        color: #FFFFFF;
      }
    }

    .tab-subtitle {
      font-size: 12px;
      color: #666666;
      margin-top: 10px;

      &-active {
        color: #FFFFFF;
      }
    }

    .tab-line {
      width: 130px;
      height: 6px;
      background: none;
      margin-top: 25px;
      margin-bottom: -6px;

      &-active {
        background: #FFEA00;
      }
    }
  }

  .img-login {
    width: 106px;
    height: 30px;
    object-fit: scale-down;
    cursor: pointer;
    margin-right: 130px;
  }
}
</style>
