<template>
	<div class="main-page">
		<!--页面头部-->
		<Header></Header>
		<div id="content" class="column-me flex1" style="overflow: auto;">
			<!-- 页面主内容 -->
			<div class="flex1">
				<!-- vue3.0配置 -->
				<router-view v-slot="{ Component }">
					<keep-alive>
						<component :is="Component" v-if="$route.meta.keepAlive" :key="$route.path" />
					</keep-alive>
					<component :is="Component" v-if="!$route.meta.keepAlive" :key="$route.path" />
				</router-view>
			</div>
			<!--页面底部-->
			<Footer></Footer>
			<!-- 回到顶部 -->
			<el-backtop target="#content"></el-backtop>
		</div>
	</div>
</template>

<script>
	import Header from '@/components/header.vue';
	import Footer from '@/components/footer.vue';

	export default {
		components: {
			Header,
			Footer,
		},
		data() {
			return {

			}
		},
		watch: {
			'$route': {
				handler(to, from) {
					if (to.name !== from.name) {
						document.getElementById('content').scrollTop = 0
					}
				},
				// 深度观察监听
				deep: true
			}
		},
		mounted() {

		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>

</style>
