const title = function(title) {
	document.title = title;
}

// 手机号验证
const is_phone = function(phone) {
	let br = true;
	if (!(/^0?(13[0-9]|14[5-9]|15[012356789]|166|17[0-9]|18[0-9]|19[8-9])[0-9]{8}$/.test(phone))) {
		br = false
	}
	return br
}
// 邮箱验证
const is_email = function(email) {
	let br = true;
	if (!(/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(email))) {
		br = false
	}
	return br
}
// 非空验证
const is_text = function(text) {
	let br = true;
	if (!(/\S/.test(text))) {
		br = false
	}
	return br
}
// 密码验证
const is_password = function(text) {
	let br = true;
	if (!(/^[a-zA-Z0-9]{6,12}$/.test(text))) {
		br = false
	}
	if (!(/^(?![^a-zA-Z]+$)(?!\D+$)/.test(text))) {
		br = false
	}
	return br
}

const isMobile = function() {
	return navigator.userAgent.match(
		/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
	)
}

// 身份证验证
const is_card = function(code) {
	//身份证号合法性验证
	//支持15位和18位身份证号
	//支持地址编码、出生日期、校验位验证
	var city = {
		11: "北京",
		12: "天津",
		13: "河北",
		14: "山西",
		15: "内蒙古",
		21: "辽宁",
		22: "吉林",
		23: "黑龙江 ",
		31: "上海",
		32: "江苏",
		33: "浙江",
		34: "安徽",
		35: "福建",
		36: "江西",
		37: "山东",
		41: "河南",
		42: "湖北 ",
		43: "湖南",
		44: "广东",
		45: "广西",
		46: "海南",
		50: "重庆",
		51: "四川",
		52: "贵州",
		53: "云南",
		54: "西藏 ",
		61: "陕西",
		62: "甘肃",
		63: "青海",
		64: "宁夏",
		65: "新疆",
		71: "台湾",
		81: "香港",
		82: "澳门",
		91: "国外 "
	};
	var row = true;
	var msg = "验证成功";

	if (!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|[xX])$/.test(code)) {
		row = false,
			msg = "被保人身份证号格式错误";
	} else if (!city[code.substr(0, 2)]) {
		row = false,
			msg = "被保人身份证号地址编码错误";
	} else {
		//18位身份证需要验证最后一位校验位
		if (code.length == 18) {
			code = code.split('');
			//∑(ai×Wi)(mod 11)
			//加权因子
			var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
			//校验位
			var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
			var sum = 0;
			var ai = 0;
			var wi = 0;
			for (var i = 0; i < 17; i++) {
				ai = code[i];
				wi = factor[i];
				sum += ai * wi;
			}
			if (parity[sum % 11] != code[17].toUpperCase()) {
				row = false,
					msg = "被保人身份证号校验位错误";
			}
		}
	}
	return row;
}

// 存储信息
const set_data = function(key, val) {
	localStorage.setItem(key, JSON.stringify(val));
}
// 读取信息
const get_data = function(key) {
	return JSON.parse(localStorage.getItem(key));
}

// 超出省略
const slh = function(str, length) {
	if (str.length > length + 3) {
		str = str.substring(0, length) + "..."
	}
	return str;
}

// 获取当前日期
function getNowDate() {
	let date = new Date();
	let year = date.getFullYear()
	let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
	let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
	return year + '-' + month + '-' + day
}
//深拷贝对象
function deepClone(obj) {
	let _obj = JSON.stringify(obj),
		objClone = JSON.parse(_obj);
	return objClone
}
//数组是否包含id的对象
function arrayContainId(array, id) {
	for (var i = 0; i < array.length; i++) {
		let item = array[i]
		if (item.id === id) {
			return true
		}
	}
	return false
}
//秒数转时分秒
//type(0): 01:02:03
//type(1): 01:02′03″
//type(2): 01时02分03秒
function formatSeconds(value, type) {
	var secondTime = parseInt(value); // 秒
	var minuteTime = 0; // 分
	var hourTime = 0; // 小时
	if (secondTime >= 60) { //如果秒数大于60，将秒数转换成整数
		//获取分钟，除以60取整数，得到整数分钟
		minuteTime = parseInt(secondTime / 60);
		//获取秒数，秒数取佘，得到整数秒数
		secondTime = parseInt(secondTime % 60);
		//如果分钟大于60，将分钟转换成小时
		if (minuteTime >= 60) {
			//获取小时，获取分钟除以60，得到整数小时
			hourTime = parseInt(minuteTime / 60);
			//获取小时后取佘的分，获取分钟除以60取佘的分
			minuteTime = parseInt(minuteTime % 60);
		}
	}
	var secondStr = '';
	var minuteStr = '';
	var hourStr = '';
	if (secondTime < 10) {
		secondStr = '0' + secondTime
	} else {
		secondStr = secondTime
	}
	if (minuteTime < 10) {
		minuteStr = '0' + minuteTime
	} else {
		minuteStr = minuteTime
	}
	if (hourTime < 10) {
		hourStr = '0' + hourTime
	} else {
		hourStr = hourTime
	}
	//type(0): 01:02:03
	//type(1): 01:02′03″
	//type(2): 01时02分03秒
	var result = "";
	if (type === 0) {
		if (hourTime > 0) {
			result += hourStr + ':' + minuteStr + ':' + secondStr
		} else {
			result += minuteStr + ':' + secondStr
		}
	} else if (type === 1) {
		if (hourTime > 0) {
			result += hourStr + ':' + minuteStr + '′' + secondStr + '″'
		} else {
			result += minuteStr + '′' + secondStr + '″'
		}
	} else if (type === 2) {
		if (hourTime > 0) {
			result += hourStr + '时' + minuteStr + '分' + secondStr + '秒'
		} else {
			result += minuteStr + '分' + secondStr + '秒'
		}
	}
	return result;
}
export default {
	title,
	// 验证手机号
	is_phone,
	// 邮箱验证
	is_email,
	// 非空验证
	is_text,
	// 密码验证
	is_password,
	// 身份证验证
	is_card,
	// 存储信息
	set_data,
	// 读取信息
	get_data,
	// 超出省略
	slh,
	//是否是手机
	isMobile,
	//获取当前日期
	getNowDate,
	//深拷贝对象
	deepClone,
	//数组包含某个id
	arrayContainId,
	//格式化秒数
	formatSeconds
}
