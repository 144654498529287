import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import defaultPage from '@/views/default.vue'

const routes = [{
  path: '/',
  name: 'defaultPage',
  redirect: '/home',
  component: defaultPage,
  children: [{
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue'),
    meta: {
      title: '',
      keepAlive: true,
    }
  }, {
    path: '/about_us',
    name: 'about_us',
    component: () => import('../views/about_us.vue'),
    meta: {
      title: '品牌介绍',
      keepAlive: true,
    }
  }, {
    path: '/sport_park',
    name: 'sport_park',
    component: () => import('../views/sport_park.vue'),
    meta: {
      title: '卡丁车',
      keepAlive: true,
    }
  }, {
    path: '/product',
    name: 'product',
    component: () => import('../views/product.vue'),
    meta: {
      title: '产品中心',
      keepAlive: true,
    }
  }, {
    path: '/course',
    name: 'course',
    component: () => import('../views/course.vue'),
    meta: {
      title: '课程中心',
      keepAlive: true,
    }
  }, {
    path: '/franchise',
    name: 'franchise',
    component: () => import('../views/franchise.vue'),
    meta: {
      title: '加盟中心',
      keepAlive: true,
    }
  }, {
    path: '/service',
    name: 'service',
    component: () => import('../views/service.vue'),
    meta: {
      title: '服务支持',
      keepAlive: true,
    }
  }, {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact.vue'),
    meta: {
      title: '联系我们',
      keepAlive: true,
    }
  }, {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/detail.vue'),
    meta: {
      title: '新闻详情',
      keepAlive: false,
    }
  }, {
    path: '/detail_honor',
    name: 'detail_honor',
    component: () => import('../views/detail_honor.vue'),
    meta: {
      title: '资质荣誉详情',
      keepAlive: false,
    }
  }, {
    path: '/detail_product',
    name: 'detail_product',
    component: () => import('../views/detail_product.vue'),
    meta: {
      title: '产品详情',
      keepAlive: false,
    }
  }, {
    path: '/travel',
    name: 'travel',
    component: () => import('../views/xiaoHuTravel.vue'),
    meta: {
      title: '小狐出行',
      keepAlive: true,
    }
  }, {
    path: '/derivative',
    name: 'derivative',
    component: () => import('../views/derivative.vue'),
    meta: {
      title: '衍生品',
      keepAlive: true,
    }
  }]
}]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
